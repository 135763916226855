<template>
  <div class="padding-y-6">
    <span style="display: inline-flex" class="margin-b-2">
      <span
        v-if="showSignedUp"
        :class="`invite-status invite-status--${toKebab(
          customerInvitationStatus
        )}`"
      >
        {{ customerInvitationStatus }}
      </span>
      <div v-if="canSendEmail" class="d-flex align-center">
        <v-btn
          id="send-email-button"
          color="primary"
          class="margin-l-0"
          flat
          :disabled="isForgotPasswordDisabled"
          @click="sendEmail"
        >
          {{ sendEmailButtonText }}
        </v-btn>
        <v-tooltip v-if="isForgotPasswordDisabled" top max-width="250" >
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              small
              v-bind="attrs"
              style="cursor: pointer"
              v-on="on"
            >
              info
            </v-icon>
          </template>
          This customer has only signed in with social login methods and cannot receive a forgot password email.
        </v-tooltip>
      </div>
    </span>
    <div v-if="isSignedUp" style="display: grid">
      <span
        class="margin-y-2"
        style="display: inline-flex; align-items: flex-end"
      >
        <p class="email-sent-date">Forgot PW Email Sent Date</p>
        {{ formatDateTime(customer.forgotPasswordEmailSentAt) }}
      </span>
      <!-- We will not be able to support this as we move towards Auth0 for customers -->
      <span v-if="!isAuth0CharterUpEnabled" style="display: inline-flex; align-items: flex-end">
        <p class="email-sent-date">Last PW Reset Date</p>
        {{ lastPasswordResetDate }}
      </span>
    </div>
    <div v-else>
      <span
        class="margin-t-2"
        style="display: inline-flex; align-items: flex-end"
      >
        <p class="email-sent-date">Sign Up Email Sent Date</p>
        {{ formatDateTime(customer.signUpEmailSentAt) }}
      </span>
    </div>
  </div>
</template>

<script>
import { toKebab } from '../utils/string'
import { mapActions } from 'vuex'
import customers from '@/services/customers'
import { authComputed } from '@/state/helpers'
import { ianaZoneToOffsetName } from '@/utils/time'
import { DateTime } from 'luxon'
import { SplitFeatureFlag } from '@/utils/enum'

export default {
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
    showSignedUp: {
      type: Boolean,
      default: true,
    },
  },
  data: {
    isAuth0CharterUpEnabled: false,
  },
  watch: {
    '$store.state.split.isReady': {
      async handler(isReady) {
        if (!isReady) {
          return
        }
        this.isAuth0CharterUpEnabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.Auth0CharterUp)
      },
      immediate: true
    }
  },
  computed: {
    ...authComputed,
    canSendEmail() {
      return (
        this.isSignedUp ||
        this.$store.getters['auth/hasPermission']('canUpdateMainContact')
      )
    },
    isForgotPasswordDisabled() {
      return this.isSignedUp && this.hasOnlySignedInWithSocial
    },
    hasOnlySignedInWithSocial() {
      return this.customer?.socialOnly
    },
    isSignedUp() {
      return !!this.customer?.isSignedUp
    },
    customerInvitationStatus() {
      return this.isSignedUp ? 'Signed Up' : 'New'
    },
    sendEmailButtonText() {
      return this.isSignedUp
        ? 'Send Forgot Password Email'
        : 'Send Sign Up Email'
    },
    lastPasswordResetDate() {
      if (!this.customer.forgotPasswordEmailSentAt) {
        return null
      }
      const forgotPasswordEmailSentAt = DateTime.fromISO(
        this.customer.forgotPasswordEmailSentAt
      )
      const passwordUpdatedAt = DateTime.fromISO(
        this.customer.passwordUpdatedAt
      )

      if (passwordUpdatedAt > forgotPasswordEmailSentAt) {
        return this.formatDateTime(this.customer.passwordUpdatedAt)
      }
      return null
    },
    customerId() {
      const customerId = this.customer.customerId

      if (typeof customerId === 'string') {
        return Number.parseInt(customerId)
      }

      return customerId
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    toKebab,
    formatDateTime(date) {
      const timeZone = this.currentUser.timeZone || 'America/New_York'
      if (!date) {
        return ''
      }
      const dateTime = DateTime.fromISO(date, { zone: timeZone })
      return `${dateTime?.toFormat('MM/d/yyyy h:mm a')} ${ianaZoneToOffsetName(
        timeZone,
        date
      )}`
    },
    async sendEmail() {
      if (this.customerInvitationStatus === 'Signed Up') {
        try {
          const response = await customers.sendCustomerForgotPasswordEmail({
            email: this.customer.email,
          })
          if (response?.data?.successful) {
            this.showAlert({
              message: 'Forgot Password Email Sent!',
              type: 'success',
            })
          } else {
            this.showAlert({
              message: response.message || 'Error sending email.',
              type: 'error',
            })
          }
        } catch (e) {
          this.showAlert({
            message: 'Error sending email.',
            type: 'error',
          })
        }
      } else if (this.customerInvitationStatus === 'New') {
        try {
          const response = await customers.sendCustomerInviteEmail(
            this.customerId
          )

          if (response?.data?.successful) {
            this.showAlert({
              message: 'Sign Up Email Sent!',
              type: 'success',
            })
          } else {
            this.showAlert({
              message: 'Error sending email.',
              type: 'error',
            })
          }
        } catch (e) {
          this.showAlert({
            message: 'Error sending email.',
            type: 'error',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.invite-status {
  padding: 3px 6px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  border-radius: 2px;
  width: max-content;

  &--signed-up {
    background: $green;
    color: white;
  }

  &--new {
    background: $primary;
    color: white;
  }
}

.send-email-button {
  margin-left: 8px;
  cursor: pointer;
  color: $primary;
}

.email-sent-date {
  margin: 0;
  margin-right: 15px;
  font-weight: bold;
  color: $gray-medium-dark;
  font-size: 13px;
}
</style>
